import './index.css';
import reportHandler from './reportHandler';
import reportWebVitals from './reportWebVitals';
import { checkVersion } from './services/cacheBuster';
import * as serviceWorker from './serviceWorker';

serviceWorker.register();
serviceWorker.addManifestFile();

let starting = true;

if (starting) {
  import('./loadingRenderer');
}

checkVersion();

import('./appStarter').then(() => {
  starting = false;
});

reportWebVitals(reportHandler);
