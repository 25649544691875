export const TWO_DECIMALS = 2;
export const REQUIRED_FIELD_STAR = '*';
export const FIVE_SECONDS = 5;
export const ONE_AND_A_HALF_SECONDS = 1500;
export const FIVE_MINUTES = 5 * 60 * 1000;
export const EIGHTHEEN = '18';
export const SINGLE_SPACE = ' ';
export const PLUS = '+';

export const PROOF_REQUEST_DOCUMENT_MAX_SIZE_KB = 8000;

export const DYNAMIC_DOMAIN = 'winiam';

export const DH = 'DH';
export const P = 'P';
