const wasCacheRecentlyCleared = () => {
  try {
    const lastTimeInSecondsWhenCacheWasCleaned = parseInt(localStorage.getItem('cacheCleanTimestamp') || '0', 10);
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    return lastTimeInSecondsWhenCacheWasCleaned + WAIT_TIME_IN_SECONDS_BEFORE_RETRY_CACHE_CLEARING > currentTimeInSeconds;
  } catch (e) {
    return false;
  }
};

// Amount of time to pause before reloading the page is allowed to clear the cache again.
// Prevents reload infinite loop if a version mismatch is detected
const WAIT_TIME_IN_SECONDS_BEFORE_RETRY_CACHE_CLEARING = 20;

const getCachedVersion = () => localStorage.getItem('cachedVersion');
const hasAcceptedCookies = () => localStorage.getItem('kwiffCookieAcceptance');

const setCachedVersion = (version: string) => {
  localStorage.setItem('cachedVersion', version);
};

export const checkVersion = async () => {
  const appVersion: string | undefined = window.app_version;
  const uniqueRandomIndexForServerRequest = new Date().getTime();
  const cacheWasRecentlyCleared = wasCacheRecentlyCleared();

  try {
    const versionJSON = await load(`/version.json?v=${uniqueRandomIndexForServerRequest}`);
    const versionData = JSON.parse(versionJSON);
    const versionFromServerCouldNotBeRead = !versionJSON || !versionData || !versionData.version;
    const versionFromServer = versionJSON && versionData && versionData.version;
    const previousCachedVersion = getCachedVersion();
    const cachedVersion = cacheWasRecentlyCleared ? versionFromServer : previousCachedVersion;
    const userHasAcceptedCookies = hasAcceptedCookies();
    const isNotFirstTimeUser = cachedVersion || userHasAcceptedCookies;
    const versionHasChanged = appVersion !== versionFromServer || (isNotFirstTimeUser && cachedVersion !== versionFromServer);

    if (cacheWasRecentlyCleared || previousCachedVersion === null) {
      setCachedVersion(String(versionFromServer));
    }

    if (versionFromServerCouldNotBeRead || versionHasChanged) {
      console.log('outdated version detected. attempting to clear cache');
      clearCacheAndOptionallyReload(true);
    } else if (previousCachedVersion === null) {
      clearCacheAndOptionallyReload(false);
    }
  } catch (e) {
    console.log('Version file could not be loaded. Clearing cache');
    clearCacheAndOptionallyReload(true);
  }
};

const clearCacheAndOptionallyReload = (reload: boolean) => {
  try {
    const cacheWasRecentlyCleared = wasCacheRecentlyCleared();

    if (cacheWasRecentlyCleared) {
      console.log('cache was recently cleared, will not clear again');
      return;
    }
    deleteCacheAndOptionallyReload(reload);
  } catch (e) {
    console.log('cache could not be cleared');
  }
};

const deleteCacheAndOptionallyReload = (reload: boolean) => {
  if (window.caches) {
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
    localStorage.setItem('cacheCleanTimestamp', String(currentTimeInSeconds));

    window.caches
      .keys()
      .then(keys => keys.forEach(key => window.caches.delete(key)))
      .then(performConditionalReloadAfterCacheClear(reload));
  } else {
    console.log('access to cache denied');
  }
};

const performConditionalReloadAfterCacheClear = (reload: boolean) => () => {
  if (reload) {
    window.location.reload();
  }
};

const load = (url: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        resolve(xhr.response || '');
      }
    };

    // tslint:disable-next-line:no-any
    xhr.onerror = (ev: any) => {
      reject(ev);
    };

    xhr.open('GET', url, true);
    xhr.send('');
  });
