import { default as TagManager } from 'react-gtm-module';
import { Metric } from 'web-vitals';

// event values can only contain integers
const getEventValueFromMetric = (metric: Metric) => {
  if (metric.name === 'CLS') {
    return Math.round(metric.value * 1000);
  }

  return Math.round(metric.value);
};

const reportHandler = (metric: Metric) => {
  TagManager.dataLayer({
    dataLayer: {
      event: `web-vitals - ${metric.name}`,
      eventValue: getEventValueFromMetric(metric),
      eventLabel: metric.id,
      nonInteraction: true,
      transport: 'beacon',
    },
  });
};

export default reportHandler;
